import { j as d, T as gt, c as I, s as yt, B as wt, g as xt, l as je, I as We, w as Fe, a as Ye, S as vt, h as Tt, b as Xe, H as _t, i as Ct, k as St } from "./ToggleSwitch-DlaivjHi.mjs";
import H, { useRef as D, useCallback as v, forwardRef as G, useMemo as R, useState as K, useEffect as E, createContext as Ge, useContext as Je, useId as ge, useLayoutEffect as Qe, createElement as At, memo as Nt } from "react";
import { createPortal as kt } from "react-dom";
const Et = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), De = {}, It = (e, t, r, n) => {
  const o = `${e}-${t ? "currency" : "noCurrency"}-${r}-${n}`;
  let i = De[o];
  return i || (i = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: r,
    maximumFractionDigits: r,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...n ? {} : { useGrouping: !1 }
  }), De[o] = i), i;
};
function _e(e, t, r = {}) {
  const n = (r == null ? void 0 : r.precision) === void 0 ? 2 : r == null ? void 0 : r.precision, a = typeof t == "number" ? t || 0 : Et(e, t), o = (r == null ? void 0 : r.thousandSeparator) ?? !0;
  return It(e, !(r != null && r.format), n, o).format(a).replace(/\u00A0/, " ");
}
function Oe(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), r = parseFloat(t) || 0;
  return Math.round(r * 100);
}
function fe(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
function Ze(e) {
  const t = D(null), r = v(
    (n) => {
      if (t.current = n, !!e) {
        if (typeof e == "function") return e(n);
        e.current = n;
      }
    },
    [e]
  );
  return [t, r];
}
function Be(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Pt = G(
  ({
    label: e,
    locale: t = "de",
    name: r,
    id: n,
    min: a = 0,
    onChange: o,
    onBlur: i,
    onFocus: c,
    defaultValue: l,
    value: s,
    step: p,
    children: g,
    className: f,
    hideSteps: b,
    max: C,
    ...T
  }, y) => {
    const m = R(() => Mt(p), [p]), [_, A] = K(
      fe(
        _e(t, s ?? l ?? "", { precision: m, format: "%v", thousandSeparator: !1 }),
        m
      )
    ), j = R(() => Be(C), [C]), P = R(() => Be(a), [a]), [F, O] = K(null), [u, h] = Ze(y), M = v(
      (x) => {
        const N = ze(x.target.value, t, m), Y = Oe(N);
        if (typeof j < "u" && Y > j || typeof P < "u" && Y < P) {
          const ue = x.target.selectionStart ? x.target.selectionStart : 0;
          return O(ue), x.preventDefault();
        }
        O(x.target.selectionStart);
        let V = x.target.value;
        N.startsWith(x.target.value) || (V = N);
        const se = Oe(x.target.value);
        x.target.value && !isNaN(se) && Y === se && (o == null || o(x)), A(V);
      },
      [t, m, P, j, o]
    ), S = v(
      (x) => {
        const N = ze(x.target.value, t, m);
        N !== x.target.value && (x.target.value = N), O(null), i == null || i(x);
      },
      [i, m, t]
    ), $ = v(
      (x) => {
        O(x.target.selectionStart), c == null || c(x);
      },
      [c]
    ), w = R(
      () => l ? { defaultValue: _ } : { value: _ },
      [l, _]
    );
    return E(() => {
      if (typeof l < "u") return;
      const x = s ?? l;
      if (typeof s > "u")
        return A("");
      const N = fe(
        _e(t, x, { precision: m, format: "%v", thousandSeparator: !1 }),
        m
      );
      A(N);
    }, [s, m, l, t]), E(() => {
      const x = (_ == null ? void 0 : _.length) ?? 0, N = (F ?? 0) > x ? x : F;
      F === null || !u.current || document.activeElement !== u.current || u.current.setSelectionRange(N, N);
    }, [F, u, _]), /* @__PURE__ */ d.jsx(
      gt,
      {
        ...T,
        ...w,
        onFocus: $,
        onBlur: S,
        onChange: M,
        step: p,
        ref: h,
        id: n || r,
        name: r,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: I(f, { [yt.hideSteps]: b }),
        children: g
      }
    );
  }
);
Pt.displayName = "ClientAmountField";
function ze(e, t, r = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const n = String(e), a = fe(n, r), o = _e(t, a, { precision: r, format: "%v", thousandSeparator: !1 }), i = fe(o, r);
  if (!r)
    return i;
  const c = i.search(/[.,]/);
  if (c < 0)
    return i;
  const l = r - (i.length - 1 - c);
  return i.concat("0".repeat(l));
}
function Mt(e) {
  var r;
  return e === void 0 ? void 0 : ((r = String(e).split(".")[1]) == null ? void 0 : r.length) ?? 0;
}
function L(e) {
  e.preventDefault(), e.stopPropagation();
}
const Lt = G(function({
  onKeyDown: t,
  onKeyUp: r,
  onKeyDownCapture: n,
  onKeyUpCapture: a,
  onClick: o,
  ...i
}, c) {
  const { disabled: l, busy: s } = i, p = l || s, g = p ? L : t, f = p ? L : r, b = p ? L : n, C = p ? L : a, T = p ? L : o;
  return /* @__PURE__ */ d.jsx(
    wt,
    {
      ...i,
      onClick: T,
      onChange: g,
      onKeyUp: f,
      onKeyDownCapture: b,
      onKeyUpCapture: C,
      ref: c
    }
  );
}), fn = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? L : t, c = o ? L : r;
  return /* @__PURE__ */ d.jsx(xt, { ...n, onChange: i, onClick: c, ref: a });
}), jt = "_tooltipArrow_qcdrl_1", Ft = "_bottomTooltipArrow_qcdrl_32", Dt = "_topTooltipArrow_qcdrl_37", xe = {
  tooltipArrow: jt,
  bottomTooltipArrow: Ft,
  topTooltipArrow: Dt
}, q = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right"
}, Q = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify"
};
function Ot({ x: e, position: t, className: r }) {
  return /* @__PURE__ */ d.jsx(
    "div",
    {
      className: I(xe.tooltipArrow, r, {
        [xe.bottomTooltipArrow]: t === q.BOTTOM,
        [xe.topTooltipArrow]: t === q.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === q.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
const Bt = "_picker_yqwd0_1", zt = "_swatch_yqwd0_7", $t = "_activeSwatch_yqwd0_19", Kt = "_inactiveSwatch_yqwd0_25", qt = "_errorSwatch_yqwd0_30", Ht = "_swatchInner_yqwd0_34", Ut = "_activePickerSwatch_yqwd0_41", Rt = "_dropdown_yqwd0_45", Wt = "_popover_yqwd0_49", Yt = "_pickerSwatches_yqwd0_55", Xt = "_input_yqwd0_68", Gt = "_label_yqwd0_91", X = {
  picker: Bt,
  swatch: zt,
  activeSwatch: $t,
  inactiveSwatch: Kt,
  errorSwatch: qt,
  swatchInner: Ht,
  activePickerSwatch: Ut,
  dropdown: Rt,
  popover: Wt,
  pickerSwatches: Yt,
  input: Xt,
  label: Gt
}, Jt = "_dropdownContent_1n9sv_1", Qt = "_dropdownTriggerButton_1n9sv_14", Zt = "_scaleTransition_1n9sv_26", Vt = "_scaleTransitionOpen_1n9sv_34", er = "_dropdownMenu_1n9sv_39", tr = "_dropdownMenuSeparator_1n9sv_50", rr = "_dropdownMenuItem_1n9sv_59", ne = {
  dropdownContent: Jt,
  dropdownTriggerButton: Qt,
  scaleTransition: Zt,
  scaleTransitionOpen: Vt,
  dropdownMenu: er,
  dropdownMenuSeparator: tr,
  dropdownMenuItem: rr
}, nr = "_popupContainer_y7ppu_1", or = "_spyElement_y7ppu_15", ar = "_nonFocusableOutline_y7ppu_21", Ee = {
  popupContainer: nr,
  spyElement: or,
  nonFocusableOutline: ar
}, cr = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, ir = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, Z = ["a", "button", "input", "textarea", "select", "details", "area", "label"];
function lr(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function sr(e, t) {
  return [...lr(e).add(t)].join(" ");
}
function ur(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const r = e.tagName.toLowerCase();
  if (r === "input") {
    const a = e.getAttribute("type");
    return a ? ir[a] : "textbox";
  }
  const n = cr[r];
  return n || "button";
}
function Ve(e, t = Z) {
  if (!(!e || !(e instanceof HTMLElement)))
    return e.querySelectorAll(`${t.join(",")},[tabindex]:not([tabindex="-1"])`);
}
function et(e, t = Z) {
  if (!e) return !1;
  const r = e.getAttribute("tabindex");
  if (!r && !t.includes(e.tagName.toLowerCase()) || r && parseInt(r) < 0 || e.hasAttribute("disabled")) return !1;
  const a = window.getComputedStyle(e);
  return !(a.display === "none" || a.visibility === "hidden");
}
function ye(e, t = Z) {
  if (!e || !(e instanceof HTMLElement)) return null;
  const r = e.querySelectorAll(
    `${t.join(",")}:not([tabindex="-1"]):not([disabled]):not([inert]),[tabindex]:not([tabindex="-1"]):not([disabled]):not([inert])`
  );
  for (const n of r)
    if (et(n, t))
      return n;
  return null;
}
function Ie(e, t = Z) {
  return !e || !(e instanceof HTMLElement) ? null : et(e, t) ? e : ye(e, t);
}
function tt(e, t, r = Z) {
  if (!t || !(t instanceof HTMLElement)) return null;
  let n = t, a = null;
  for (; (n = e(n)) && !(a = Ie(n, r)); )
    ;
  return a;
}
function rt(e, t = Z) {
  return tt((r) => (r == null ? void 0 : r.nextElementSibling) ?? null, e, t);
}
function pr(e, t = Z) {
  return tt((r) => (r == null ? void 0 : r.previousElementSibling) ?? null, e, t);
}
function nt(e, t = Z) {
  if (!e || !(e instanceof HTMLElement)) return null;
  let r = e;
  for (; ; ) {
    const n = rt(r, t);
    if (n) return n;
    if (r = (r == null ? void 0 : r.parentElement) ?? null, !r || r === e) return null;
  }
}
async function dr() {
  return new Promise((e) => {
    setTimeout(() => window.requestAnimationFrame(() => e()));
  });
}
const Pe = "data-popup", Ce = "data-popup-id";
function $e(e) {
  let t = e;
  for (; t; ) {
    if (t != null && t.hasAttribute(Pe)) return t;
    t = (t == null ? void 0 : t.parentElement) ?? null;
  }
  return null;
}
function mr(e, t) {
  if (!t || !e) return !1;
  const r = $e(t);
  if (!r) return !1;
  const n = r.getAttribute(Pe);
  if (!n) return !1;
  if (e.getAttribute(Ce) === n) return !0;
  const a = $e(e);
  return a ? !!a.querySelector(`[${Ce}="${n}"]`) : !1;
}
function ce(e) {
  return e ? e.clientHeight && e.scrollHeight > e.clientHeight || e.tagName === "DIALOG" ? e.tagName === "HTML" ? window : e : ce(e.parentNode) : null;
}
const ot = Ge({
  initialised: !1,
  open: !1,
  inDOM: !1,
  setInDOM: () => {
  },
  showPopup: () => {
  },
  hidePopup: () => {
  },
  togglePopup: () => {
  },
  hasClickTrigger: !1,
  hasKeypressTrigger: !1,
  hasFocusTrigger: !1,
  hasHoverTrigger: !1
});
function ie() {
  return Je(ot);
}
function fr(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function at(e) {
  return (t) => t === e || t && fr(t, e) ? t : e;
}
const z = {
  Enter: "Enter",
  Space: " ",
  Tab: "Tab",
  Escape: "Escape",
  Down: "ArrowDown",
  Up: "ArrowUp",
  Left: "ArrowLeft",
  Right: "ArrowRight"
};
function be() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "popup"), document.body.appendChild(t), t;
}
function br(e) {
  if (!e) return null;
  const { height: t, width: r } = e;
  return {
    height: t,
    width: r
  };
}
function hr({ triggerRect: e, id: t, onCloseTransitionEnd: r, onPopupLeave: n, children: a }) {
  const {
    popupRef: o,
    spyRef: i,
    preferredPopupPosition: c,
    alignPopup: l,
    hasHoverTrigger: s,
    Anchor: p,
    contentClassName: g,
    contentWrapperClassName: f,
    popupOpenWrapperClassName: b,
    anchorClassName: C
  } = ie(), [T, y] = K(null), [m, _] = K(null), [A, j] = K(null), [P, F] = K(!1), { open: O, hidePopup: u, popupRef: h } = ie();
  E(() => {
    if (!O) return F(!1);
    const k = window.requestAnimationFrame(() => {
      F(!0);
    });
    return () => window.cancelAnimationFrame(k);
  }, [O, F]);
  const M = v(() => {
    var B;
    const k = br((B = o == null ? void 0 : o.current) == null ? void 0 : B.getBoundingClientRect());
    y(at(k));
  }, [o]), S = v(() => {
    const k = ce(be()) ?? document.body, W = (k === window ? document.body : k).getBoundingClientRect();
    _(W.width), j(W.height);
  }, []), $ = v(
    (k) => {
      var W;
      if (k.key !== z.Escape) return;
      k.preventDefault();
      const B = (W = i == null ? void 0 : i.current) == null ? void 0 : W.nextElementSibling;
      B == null || B.focus(), u();
    },
    [u, i]
  );
  E(() => {
    const k = o == null ? void 0 : o.current;
    if (!k) return;
    const B = Ve(k);
    return B == null || B.forEach((W, de) => {
      W.addEventListener("keydown", $);
    }), () => {
      B == null || B.forEach((W, de) => {
        W.removeEventListener("keydown", $);
      });
    };
  }, [a, $, o]), Qe(() => {
    M(), S();
  }, [M, S]);
  const w = v(() => {
    window.requestAnimationFrame(() => {
      M(), S();
    });
  }, [M, S]), x = v(
    (k) => {
      s && n(k);
    },
    [n, s]
  ), N = v(
    (k) => {
      var W, de, Le;
      const B = (de = (W = h == null ? void 0 : h.current) == null ? void 0 : W.firstElementChild) == null ? void 0 : de.firstElementChild;
      if (k.target !== B && !((Le = o == null ? void 0 : o.current) != null && Le.contains(k.relatedTarget))) {
        if (mr(B, k.relatedTarget))
          return k.preventDefault();
        n(k);
      }
    },
    [n, h, o]
  );
  E(() => (window.addEventListener("resize", w), () => {
    window.removeEventListener("resize", w);
  }), [w]);
  const Y = R(() => p ? 17 : 0, [p]);
  E(() => {
    if (O) return;
    const k = o == null ? void 0 : o.current;
    if (!b) return r();
    k == null || k.addEventListener("transitionend", r);
    const B = setTimeout(r, 300);
    return () => {
      clearTimeout(B), k == null || k.removeEventListener("transitionend", r);
    };
  }, [o, O, r, b]);
  const {
    x: V,
    y: se,
    anchorOffsetX: ue,
    position: pe,
    width: ht
  } = R(
    () => vr({
      triggerRect: e,
      popupSize: T,
      bodyWidth: m,
      bodyHeight: A,
      anchorSize: Y,
      preferredPopupPosition: c,
      alignPopup: l
    }),
    [e, T, m, A, Y, c, l]
  );
  return kt(
    /* @__PURE__ */ d.jsx(
      "div",
      {
        id: t,
        className: Ee.popupContainer,
        ref: o,
        onPointerLeave: x,
        onBlur: N,
        style: {
          transform: `translate3d(${V}px, ${se}px, 0)`
        },
        children: /* @__PURE__ */ d.jsxs(
          "div",
          {
            className: I(
              f,
              b && { [b]: P }
            ),
            children: [
              p && pe === q.BOTTOM && /* @__PURE__ */ d.jsx(p, { position: pe, x: ue, className: C }),
              /* @__PURE__ */ d.jsx(
                "div",
                {
                  tabIndex: -1,
                  className: g,
                  style: {
                    ...l === Q.JUSTIFY ? { minWidth: ht, overflowX: "hidden" } : {}
                  },
                  [Pe]: t,
                  children: a
                }
              ),
              p && pe === q.TOP && /* @__PURE__ */ d.jsx(p, { position: pe, x: ue, className: C })
            ]
          }
        )
      }
    ),
    be()
  );
}
function gr(e, t, r) {
  E(() => {
    var p;
    const n = (p = r == null ? void 0 : r.current) == null ? void 0 : p.nextElementSibling;
    if (!n) return;
    const a = ye(n), o = a ?? n;
    n.setAttribute(Ce, e);
    const c = o.getAttribute("aria-roledescription") ?? ur(o) ?? "button";
    o.setAttribute("role", "button"), a || (o == null || o.setAttribute("tabindex", "0"), o == null || o.classList.add(Ee.nonFocusableOutline)), o.setAttribute("aria-roledescription", c);
    const l = o.getAttribute("aria-describedby");
    (l == null ? void 0 : l.includes(e)) || o.setAttribute("aria-describedby", sr(l, e));
  }, [t, e, r]);
}
function yr(e, t, r) {
  if (!e) return null;
  const { left: n, top: a, bottom: o, width: i } = e;
  return {
    left: n,
    top: a,
    bottom: o,
    width: i,
    offsetX: r,
    offsetY: t
  };
}
function wr({ children: e, content: t, id: r }) {
  const {
    showPopup: n,
    hidePopup: a,
    togglePopup: o,
    open: i,
    spyRef: c,
    popupRef: l,
    hasClickTrigger: s,
    hasFocusTrigger: p,
    hasHoverTrigger: g,
    hasKeypressTrigger: f,
    setInDOM: b
  } = ie(), [C, T] = K(null), y = R(() => !!C, [C]);
  gr(r, e, c);
  const m = v(() => {
    var x;
    const u = (x = c == null ? void 0 : c.current) == null ? void 0 : x.nextSibling;
    let h = u == null ? void 0 : u.getBoundingClientRect();
    h != null && h.height || (h = void 0);
    let M = 0, S = 0;
    const $ = ce(be()) ?? window;
    if ($ !== window) {
      const N = $;
      M = -N.getBoundingClientRect().top + N.scrollTop, S = -N.getBoundingClientRect().left + N.scrollLeft;
    }
    const w = yr(h, M, S);
    T(at(w)), b(!0);
  }, [b, c]), _ = v(() => {
    T(null), b(!1);
  }, [b]), A = v(() => {
    y && m();
  }, [m, y]), j = v(
    (u) => {
      const h = l == null ? void 0 : l.current;
      if (u.relatedTarget instanceof Node && (h != null && h.contains(u.relatedTarget)))
        return u.preventDefault();
      a();
    },
    [a, l]
  ), P = v(
    (u) => {
      var h, M;
      if (u.relatedTarget instanceof Node && ((M = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextSibling) != null && M.contains(u.relatedTarget)))
        return u.preventDefault();
      a();
    },
    [a, c]
  ), F = v(
    (u) => {
      var M, S, $;
      const h = u.target instanceof Node;
      h && ((M = l == null ? void 0 : l.current) != null && M.contains(u.target)) || h && (($ = (S = c == null ? void 0 : c.current) == null ? void 0 : S.nextElementSibling) != null && $.contains(u.target)) || P(u);
    },
    [P, l, c]
  ), O = v(
    (u) => {
      if (u.key === z.Enter || u.key === z.Space)
        return u.preventDefault(), o();
      if (u.key === z.Escape) return a();
    },
    [a, o]
  );
  return E(() => {
    if (s)
      return document.addEventListener("pointerdown", F), () => {
        document.removeEventListener("pointerdown", F);
      };
  }, [s, F]), E(() => {
    var h;
    if (!s) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("pointerdown", o), () => {
      u == null || u.removeEventListener("pointerdown", o);
    };
  }, [s, o, c, e]), E(() => {
    var h;
    if (!g) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("mouseover", n), u == null || u.addEventListener("mouseleave", j), () => {
      u == null || u.removeEventListener("mouseover", n), u == null || u.removeEventListener("mouseleave", j);
    };
  }, [g, j, n, c, e]), E(() => {
    var h;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("focusout", j), () => u == null ? void 0 : u.removeEventListener("focusout", j);
  }, [j, c, e]), E(() => {
    var h;
    if (!p) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("focus", n), u == null || u.addEventListener("focusin", n), () => {
      u == null || u.removeEventListener("focus", n), u == null || u.removeEventListener("focusin", n);
    };
  }, [p, n, e, c]), E(() => {
    var h;
    if (!f) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("keydown", O), () => {
      u == null || u.removeEventListener("keydown", O);
    };
  }, [f, O, c, e]), E(() => {
    if (!i) return;
    window.addEventListener("resize", A, { passive: !0 });
    const u = ce(be()) ?? window;
    return u.addEventListener("scroll", A, { passive: !0 }), () => {
      window.removeEventListener("resize", A), u.removeEventListener("scroll", A);
    };
  }, [i, A]), Qe(() => {
    A();
  }, [e, A]), E(() => {
    i && m();
  }, [i, m]), /* @__PURE__ */ d.jsxs(d.Fragment, { children: [
    /* @__PURE__ */ d.jsx("div", { ref: c, className: Ee.spyElement }),
    e,
    y && /* @__PURE__ */ d.jsx(
      hr,
      {
        triggerRect: C,
        onCloseTransitionEnd: _,
        id: r,
        onPopupLeave: P,
        children: t
      }
    )
  ] });
}
function xr({
  children: e,
  content: t,
  id: r,
  triggerEvents: n = ["hover", "focus"],
  inactive: a,
  ...o
}) {
  const [i, c] = K(!1), [l, s] = K(!1), [p, g] = K(!1), f = D(null), b = D(null), C = ie(), T = ge(), y = R(() => r ?? T, [r]), m = R(() => n == null ? void 0 : n.includes("click"), [n]), _ = R(() => n == null ? void 0 : n.includes("focus"), [n]), A = R(() => n == null ? void 0 : n.includes("hover"), [n]), j = R(() => n == null ? void 0 : n.includes("keypress"), [n]), P = v(() => {
    s(!1);
  }, []), F = v(() => {
    s(!0);
  }, []), O = v(() => {
    s((u) => !u);
  }, []);
  return E(() => (c(!0), () => {
    c(!1);
  }), []), !i || a ? e : /* @__PURE__ */ d.jsx(
    ot.Provider,
    {
      value: {
        open: l,
        showPopup: F,
        hidePopup: P,
        togglePopup: O,
        spyRef: b,
        popupRef: f,
        hasClickTrigger: m,
        hasHoverTrigger: A,
        hasFocusTrigger: _,
        hasKeypressTrigger: j,
        inDOM: p,
        setInDOM: g,
        initialised: !0,
        parentContext: C,
        ...o
      },
      children: /* @__PURE__ */ d.jsx(wr, { content: t, id: y, children: e })
    }
  );
}
function vr({
  triggerRect: e,
  popupSize: t,
  bodyWidth: r,
  bodyHeight: n,
  originalMargin: a = 20,
  anchorSize: o = 17,
  preferredPopupPosition: i = q.TOP,
  alignPopup: c = Q.CENTER
}) {
  let l = 0, s = 0, p = 0, g = 0, f = i, b = (t == null ? void 0 : t.width) ?? 0;
  if (!e || !t || !r || !n)
    return { x: l, y: s, anchorOffsetX: g, position: f, width: b };
  let C = 0, T = t.width / 2;
  const y = e.width / 2;
  c === Q.CENTER ? l = e.left + e.offsetX - T + y : c === Q.LEFT || c === Q.JUSTIFY ? (l = e.left + e.offsetX, c === Q.JUSTIFY && (b = e.width, T = y)) : c === Q.RIGHT && (l = e.left + e.offsetX + e.width - b);
  const m = t.width > r ? 0 : Math.min(a, (r - b) / 2);
  if (C = l, p = l + t.width, l < m ? l = m : p > r - m && (l -= p - (r - m)), o) {
    g = C - l + T - o;
    const _ = g + 2 * o - b;
    if (_ > 0) {
      const A = Math.min(_, m);
      l += A, g -= A;
    }
  }
  return i === q.TOP ? (s = e.top + e.offsetY - t.height, s < Math.abs(e.offsetY) && (f = q.BOTTOM, s = e.bottom)) : (s = e.bottom + e.offsetY, s + t.height > n + e.offsetY && (f = q.TOP, s = e.top + e.offsetY - t.height)), { x: l, y: s, anchorOffsetX: g, position: f, width: b };
}
const Tr = ["click", "keypress"];
function bn({
  children: e,
  style: t,
  className: r,
  id: n
}) {
  return /* @__PURE__ */ d.jsxs(
    "button",
    {
      id: n,
      style: t,
      className: I(je.selectContainer, je.select, ne.dropdownTriggerButton, r),
      type: "button",
      children: [
        e,
        /* @__PURE__ */ d.jsx(We, { name: "arrowDown" })
      ]
    }
  );
}
function hn({
  children: e,
  className: t,
  required: r,
  hint: n,
  id: a
}) {
  return /* @__PURE__ */ d.jsx("div", { children: e ? /* @__PURE__ */ d.jsxs("label", { htmlFor: a, className: I(Fe.label, t), children: [
    /* @__PURE__ */ d.jsxs("div", { children: [
      e,
      r && /* @__PURE__ */ d.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    n ? /* @__PURE__ */ d.jsx("div", { className: I(Fe.hint), children: n }) : null
  ] }) : null });
}
function gn({ children: e, className: t }) {
  return /* @__PURE__ */ d.jsx("ul", { className: I(ne.dropdownMenu, t), children: e });
}
function yn({
  children: e,
  style: t,
  className: r
}) {
  const n = D(null), { preferredPopupPosition: a, hidePopup: o, parentContext: i, spyRef: c } = oe(), l = v(
    (s) => {
      var f, b;
      const p = i && a, g = p && ct[p].close;
      if (s.key === z.Down || s.key === z.Up) {
        s.preventDefault();
        const C = s.key === z.Up ? pr(n.current) : rt(n.current);
        C == null || C.focus();
        return;
      }
      s.key === g && (s.preventDefault(), (b = Ie((f = c == null ? void 0 : c.current) == null ? void 0 : f.nextElementSibling)) == null || b.focus(), o()), s.key === z.Enter && s.target instanceof HTMLElement && "click" in s.target && s.target.click();
    },
    [o, i, a, c]
  );
  return E(() => {
    const s = ye(n.current);
    if (s)
      return s.addEventListener("keydown", l), s.addEventListener("click", o), () => {
        s.removeEventListener("keydown", l), s.removeEventListener("click", o);
      };
  }, [o, l]), /* @__PURE__ */ d.jsx("li", { ref: n, style: t, className: I(ne.dropdownMenuItem, r), children: e });
}
function wn() {
  return /* @__PURE__ */ d.jsx("li", { className: ne.dropdownMenuSeparator });
}
const ct = {
  [q.BOTTOM]: { open: z.Down, close: z.Down },
  [q.TOP]: { open: z.Up, close: z.Down },
  [q.RIGHT]: { open: z.Right, close: z.Left },
  [q.LEFT]: { open: z.Left, close: z.Right }
};
function _r({ children: e }) {
  const { spyRef: t, popupRef: r, showPopup: n, preferredPopupPosition: a } = oe(), o = v(
    (i) => {
      const c = a ?? q.BOTTOM, l = ct[c].open;
      i.key === l && (i.preventDefault(), n(), dr().then(() => {
        var p;
        let s = ye(r == null ? void 0 : r.current);
        if (s) {
          s.focus();
          return;
        }
        i.key === z.Tab && (s = nt((p = t == null ? void 0 : t.current) == null ? void 0 : p.nextElementSibling), s == null || s.focus());
      }));
    },
    [r, a, n, t]
  );
  return E(() => {
    var c;
    const i = (c = t == null ? void 0 : t.current) == null ? void 0 : c.nextElementSibling;
    if (i)
      return i.addEventListener("keydown", o), () => i.removeEventListener("keydown", o);
  }, [o, t]), e;
}
function Cr({ children: e }) {
  const { spyRef: t, popupRef: r, hidePopup: n, open: a } = oe(), o = v(
    (c) => {
      var l, s;
      c.key !== z.Tab || !c.shiftKey || (c.preventDefault(), (s = Ie((l = t == null ? void 0 : t.current) == null ? void 0 : l.nextElementSibling)) == null || s.focus(), n());
    },
    [t, n]
  ), i = v(
    (c) => {
      var s;
      if (c.key !== z.Tab || c.shiftKey) return;
      c.preventDefault();
      const l = nt((s = t == null ? void 0 : t.current) == null ? void 0 : s.nextElementSibling);
      l == null || l.focus();
    },
    [t]
  );
  return E(() => {
    const c = r == null ? void 0 : r.current;
    if (!c) return;
    const l = Ve(c);
    if (!l || !l.length) return;
    const s = l.item(0), p = l.item(l.length - 1);
    return s.addEventListener("keydown", o), p.addEventListener("keydown", i), () => {
      s.removeEventListener("keydown", o), p.removeEventListener("keydown", i);
    };
  }, [o, i, t, r, e, a]), e;
}
function Sr({
  trigger: e,
  children: t,
  className: r,
  alignContent: n = Q.JUSTIFY,
  wrapperClassName: a,
  anchorClassName: o,
  preferredPopupPosition: i,
  Anchor: c
}) {
  const { parentContext: l } = oe(), s = i ?? (l ? l.preferredPopupPosition ?? q.RIGHT : q.BOTTOM);
  return /* @__PURE__ */ d.jsx(
    xr,
    {
      content: /* @__PURE__ */ d.jsx(Cr, { children: t }),
      contentClassName: I(ne.dropdownContent, r),
      contentWrapperClassName: I(ne.scaleTransition, a),
      popupOpenWrapperClassName: ne.scaleTransitionOpen,
      triggerEvents: Tr,
      preferredPopupPosition: s,
      alignPopup: n,
      anchorClassName: o,
      Anchor: c,
      children: /* @__PURE__ */ d.jsx(_r, { children: e })
    }
  );
}
function oe() {
  const {
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: a,
    inDOM: o,
    parentContext: i,
    alignPopup: c,
    preferredPopupPosition: l
  } = ie(), s = i && i.initialised ? i : void 0;
  return {
    open: o,
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: a,
    parentContext: s,
    alignPopup: c,
    preferredPopupPosition: l
  };
}
function we() {
  return (we = Object.assign || function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = arguments[t];
      for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
    }
    return e;
  }).apply(this, arguments);
}
function Me(e, t) {
  if (e == null) return {};
  var r, n, a = {}, o = Object.keys(e);
  for (n = 0; n < o.length; n++) t.indexOf(r = o[n]) >= 0 || (a[r] = e[r]);
  return a;
}
function he(e) {
  var t = D(e), r = D(function(n) {
    t.current && t.current(n);
  });
  return t.current = e, r.current;
}
var le = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = 1), e > r ? r : e < t ? t : e;
}, ae = function(e) {
  return "touches" in e;
}, Se = function(e) {
  return e && e.ownerDocument.defaultView || self;
}, Ke = function(e, t, r) {
  var n = e.getBoundingClientRect(), a = ae(t) ? function(o, i) {
    for (var c = 0; c < o.length; c++) if (o[c].identifier === i) return o[c];
    return o[0];
  }(t.touches, r) : t;
  return { left: le((a.pageX - (n.left + Se(e).pageXOffset)) / n.width), top: le((a.pageY - (n.top + Se(e).pageYOffset)) / n.height) };
}, qe = function(e) {
  !ae(e) && e.preventDefault();
}, it = H.memo(function(e) {
  var t = e.onMove, r = e.onKey, n = Me(e, ["onMove", "onKey"]), a = D(null), o = he(t), i = he(r), c = D(null), l = D(!1), s = R(function() {
    var b = function(y) {
      qe(y), (ae(y) ? y.touches.length > 0 : y.buttons > 0) && a.current ? o(Ke(a.current, y, c.current)) : T(!1);
    }, C = function() {
      return T(!1);
    };
    function T(y) {
      var m = l.current, _ = Se(a.current), A = y ? _.addEventListener : _.removeEventListener;
      A(m ? "touchmove" : "mousemove", b), A(m ? "touchend" : "mouseup", C);
    }
    return [function(y) {
      var m = y.nativeEvent, _ = a.current;
      if (_ && (qe(m), !function(j, P) {
        return P && !ae(j);
      }(m, l.current) && _)) {
        if (ae(m)) {
          l.current = !0;
          var A = m.changedTouches || [];
          A.length && (c.current = A[0].identifier);
        }
        _.focus(), o(Ke(_, m, c.current)), T(!0);
      }
    }, function(y) {
      var m = y.which || y.keyCode;
      m < 37 || m > 40 || (y.preventDefault(), i({ left: m === 39 ? 0.05 : m === 37 ? -0.05 : 0, top: m === 40 ? 0.05 : m === 38 ? -0.05 : 0 }));
    }, T];
  }, [i, o]), p = s[0], g = s[1], f = s[2];
  return E(function() {
    return f;
  }, [f]), H.createElement("div", we({}, n, { onTouchStart: p, onMouseDown: p, className: "react-colorful__interactive", ref: a, onKeyDown: g, tabIndex: 0, role: "slider" }));
}), lt = function(e) {
  return e.filter(Boolean).join(" ");
}, st = function(e) {
  var t = e.color, r = e.left, n = e.top, a = n === void 0 ? 0.5 : n, o = lt(["react-colorful__pointer", e.className]);
  return H.createElement("div", { className: o, style: { top: 100 * a + "%", left: 100 * r + "%" } }, H.createElement("div", { className: "react-colorful__pointer-fill", style: { backgroundColor: t } }));
}, re = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = Math.pow(10, t)), Math.round(r * e) / r;
}, Ar = function(e) {
  var t = e.s, r = e.v, n = e.a, a = (200 - t) * r / 100;
  return { h: re(e.h), s: re(a > 0 && a < 200 ? t * r / 100 / (a <= 100 ? a : 200 - a) * 100 : 0), l: re(a / 2), a: re(n, 2) };
}, Ae = function(e) {
  var t = Ar(e);
  return "hsl(" + t.h + ", " + t.s + "%, " + t.l + "%)";
};
H.memo(function(e) {
  var t = e.hue, r = e.onChange, n = lt(["react-colorful__hue", e.className]);
  return H.createElement("div", { className: n }, H.createElement(it, { onMove: function(a) {
    r({ h: 360 * a.left });
  }, onKey: function(a) {
    r({ h: le(t + 360 * a.left, 0, 360) });
  }, "aria-label": "Hue", "aria-valuenow": re(t), "aria-valuemax": "360", "aria-valuemin": "0" }, H.createElement(st, { className: "react-colorful__hue-pointer", left: t / 360, color: Ae({ h: t, s: 100, v: 100, a: 1 }) })));
});
H.memo(function(e) {
  var t = e.hsva, r = e.onChange, n = { backgroundColor: Ae({ h: t.h, s: 100, v: 100, a: 1 }) };
  return H.createElement("div", { className: "react-colorful__saturation", style: n }, H.createElement(it, { onMove: function(a) {
    r({ s: 100 * a.left, v: 100 - 100 * a.top });
  }, onKey: function(a) {
    r({ s: le(t.s + 100 * a.left, 0, 100), v: le(t.v - 100 * a.top, 0, 100) });
  }, "aria-label": "Color", "aria-valuetext": "Saturation " + re(t.s) + "%, Brightness " + re(t.v) + "%" }, H.createElement(st, { className: "react-colorful__saturation-pointer", top: 1 - t.v / 100, left: t.s / 100, color: Ae(t) })));
});
var Nr = /^#?([0-9A-F]{3,8})$/i, kr = function(e) {
  var t = e.color, r = t === void 0 ? "" : t, n = e.onChange, a = e.onBlur, o = e.escape, i = e.validate, c = e.format, l = e.process, s = Me(e, ["color", "onChange", "onBlur", "escape", "validate", "format", "process"]), p = K(function() {
    return o(r);
  }), g = p[0], f = p[1], b = he(n), C = he(a), T = v(function(m) {
    var _ = o(m.target.value);
    f(_), i(_) && b(l ? l(_) : _);
  }, [o, l, i, b]), y = v(function(m) {
    i(m.target.value) || f(o(r)), C(m);
  }, [r, o, i, C]);
  return E(function() {
    f(o(r));
  }, [r, o]), H.createElement("input", we({}, s, { value: c ? c(g) : g, spellCheck: "false", onChange: T, onBlur: y }));
}, He = function(e) {
  return "#" + e;
}, Er = function(e) {
  var t = e.prefixed, r = e.alpha, n = Me(e, ["prefixed", "alpha"]), a = v(function(i) {
    return i.replace(/([^0-9A-F]+)/gi, "").substring(0, r ? 8 : 6);
  }, [r]), o = v(function(i) {
    return function(c, l) {
      var s = Nr.exec(c), p = s ? s[1].length : 0;
      return p === 3 || p === 6 || !!l && p === 4 || !!l && p === 8;
    }(i, r);
  }, [r]);
  return H.createElement(kr, we({}, n, { escape: a, format: t ? He : void 0, process: He, validate: o }));
};
const Ir = {
  "betterplace-dimension-0": "1rem",
  "betterplace-dimension-1": "1.25rem",
  "betterplace-dimension-2": "1.5rem",
  "betterplace-dimension-3": "2rem",
  "betterplace-dimension-4": "3rem",
  "betterplace-dimension-5": "4rem",
  "betterplace-dimension-6": "5rem",
  "betterplace-dimension-7": "6.25rem",
  "betterplace-dimension-8": "12.5rem",
  "betterplace-dimension-9": "20rem",
  "betterplace-dimension-10": "30rem",
  "betterplace-dimension--1": "0.75rem",
  "betterplace-dimension--2": "0.5rem",
  "betterplace-dimension--3": "0.25rem",
  "betterplace-dimension--4": "0.125rem",
  "betterplace-dimension--5": "0.0625rem",
  "betterplace-dimension-none": "0rem",
  "betterplace-dimension-negative-1": "-0.75rem",
  "betterplace-dimension-negative-2": "-0.5rem",
  "betterplace-dimension-negative-3": "-0.25rem",
  "betterplace-dimension-negative-4": "-0.125rem",
  "betterplace-dimension-negative-5": "-0.0625rem",
  "betterplace-opacity-low": "10%",
  "betterplace-opacity-md": "50%",
  "betterplace-opacity-high": "90%",
  "betterplace-letter-spacing-0": "0%",
  "betterplace-letter-spacing-increased": "150%",
  "betterplace-letter-spacing-decreased": "-5%",
  "betterplace-paragraph-spacing-0": "0",
  "betterplace-paragraph-spacing-md": "0.75rem",
  "betterplace-paragraph-spacing-sm": "0.5rem",
  "betterplace-color-gray-100": "#f8f8f8",
  "betterplace-color-gray-200": "#eeeeee",
  "betterplace-color-gray-300": "#cccccc",
  "betterplace-color-gray-400": "#acacac",
  "betterplace-color-gray-500": "#919191",
  "betterplace-color-gray-600": "#7b7b7b",
  "betterplace-color-gray-700": "#636363",
  "betterplace-color-gray-800": "#3f3f3f",
  "betterplace-color-gray-900": "#282828",
  "betterplace-color-green-100": "#f5fccc",
  "betterplace-color-green-200": "#eaf99a",
  "betterplace-color-green-300": "#d6ef66",
  "betterplace-color-green-400": "#bedf40",
  "betterplace-color-green-500": "#9ecb0a",
  "betterplace-color-green-600": "#85ac1c",
  "betterplace-color-green-700": "#549205",
  "betterplace-color-green-800": "#357c00",
  "betterplace-color-green-900": "#2a5807",
  "betterplace-color-purple-100": "#f7dae8",
  "betterplace-color-purple-200": "#f0b7d8",
  "betterplace-color-purple-300": "#d387ba",
  "betterplace-color-purple-400": "#a75c96",
  "betterplace-color-purple-500": "#833c79",
  "betterplace-color-purple-600": "#6d2c64",
  "betterplace-color-purple-700": "#612058",
  "betterplace-color-purple-800": "#4d1a43",
  "betterplace-color-purple-900": "#381835",
  "betterplace-color-teal-100": "#e4fbee",
  "betterplace-color-teal-200": "#c9f7e2",
  "betterplace-color-teal-300": "#a7e7d0",
  "betterplace-color-teal-400": "#88d0bd",
  "betterplace-color-teal-500": "#60b2a4",
  "betterplace-color-teal-600": "#309b89",
  "betterplace-color-teal-700": "#2b8475",
  "betterplace-color-teal-800": "#1e6761",
  "betterplace-color-teal-900": "#114e47",
  "betterplace-color-yellow-100": "#fff4d2",
  "betterplace-color-yellow-200": "#fbe8af",
  "betterplace-color-yellow-300": "#f9da7c",
  "betterplace-color-yellow-400": "#f6ce46",
  "betterplace-color-yellow-500": "#f3b700",
  "betterplace-color-yellow-600": "#f3a900",
  "betterplace-color-yellow-700": "#f39600",
  "betterplace-color-yellow-800": "#d86c00",
  "betterplace-color-yellow-900": "#c44400",
  "betterplace-color-red-100": "#fee2e2",
  "betterplace-color-red-200": "#ffc4c4",
  "betterplace-color-red-300": "#fca5a5",
  "betterplace-color-red-400": "#f87171",
  "betterplace-color-red-500": "#ef4444",
  "betterplace-color-red-600": "#dc2626",
  "betterplace-color-red-700": "#d32b43",
  "betterplace-color-red-800": "#b11b3e",
  "betterplace-color-red-900": "#931c38",
  "betterplace-color-light": "#ffffff",
  "betterplace-color-darkness": "#000000",
  "betterplace-color-blue-pruple-500": "#7d83ff",
  "betterplace-color-blue-purple-600": "#4557cb",
  "betterplace-color-blue-dark": "#245d8c",
  "betterplace-color-red-alt": "#fc440f",
  "betterplace-color-cream": "#fbfbf2",
  "betterplace-color-opacity-white-40": "rgba(255, 255, 255, 0.4)",
  "betterplace-color-opacity-white-70": "rgba(255, 255, 255, 0.7)",
  "betterplace-color-opacity-black-10": "rgba(0, 0, 0, 0.1)",
  "betterplace-color-opacity-black-20": "rgba(0, 0, 0, 0.2)",
  "betterplace-color-opacity-black-30": "rgba(0, 0, 0, 0.3)",
  "betterplace-color-opacity-black-40": "rgba(0, 0, 0, 0.4)",
  "betterplace-color-opacity-black-50": "rgba(0, 0, 0, 0.5)",
  "betterplace-color-opacity-black-60": "rgba(0, 0, 0, 0.6)",
  "betterplace-color-opacity-black-70": "rgba(0, 0, 0, 0.7)",
  "betterplace-color-opacity-black-80": "rgba(0, 0, 0, 0.8)",
  "betterplace-color-opacity-black-90": "rgba(0, 0, 0, 0.9)",
  "betterplace-me-color-orange-500": "#ff854d",
  "betterplace-me-color-orange-600": "#DB6038",
  "betterplace-me-color-orange-800": "#6F1F06",
  "betterplace-me-color-blue-100": "#E0F4F9",
  "betterplace-me-color-blue-500": "#00A0C8",
  "betterplace-me-color-blue-700": "#005D90",
  "betterplace-me-color-green-special": "#EBF2E6",
  "betterplace-external-color-blue-facebook": "#4E69A2",
  "betterplace-external-color-blue-twitter": "#1DA1F2",
  "betterplace-external-color-pink-instagram": "#E65083",
  "betterplace-external-color-blue-linkedin": "#0A66C2",
  "betterplace-external-color-red-sparkasse": "#ff0000",
  "betterplace-font-families-fira-sans": "Fira Sans",
  "betterplace-font-weights-regular": 400,
  "betterplace-font-weights-bold": 700,
  "betterplace-text-case-none": "none",
  "betterplace-text-case-capitalize": "capitalize",
  "betterplace-text-decoration-none": "none",
  "betterplace-text-decoration-underline": "underline",
  "betterplace-line-heights-xs": 1.1,
  "betterplace-line-heights-sm": 1.2,
  "betterplace-line-heights-base": 1.5,
  "betterplace-variables-min-font-size": "1rem",
  "betterplace-variables-max-font-size": "1.125rem",
  "betterplace-variables-fluid-root-font-size": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-variables-font-size-scale-ratio": "1.1892",
  "betterplace-variables-width-of-zero-glyph": "0.5583",
  "betterplace-units-ch": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem) * 0.5583",
  "betterplace-units-rem": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-font-size-0": "1rem",
  "betterplace-font-size-1": "1.189rem",
  "betterplace-font-size-2": "1.414rem",
  "betterplace-font-size-3": "1.682rem",
  "betterplace-font-size-4": "2rem",
  "betterplace-font-size-5": "2.378rem",
  "betterplace-font-size-6": "2.828rem",
  "betterplace-font-size-7": "3.363rem",
  "betterplace-font-size-8": "4rem",
  "betterplace-font-size-9": "4.757rem",
  "betterplace-font-size-10": "5.657rem",
  "betterplace-font-size--3": "0.595rem",
  "betterplace-font-size--2": "0.707rem",
  "betterplace-font-size--1": "0.878rem",
  "betterplace-measure-2xs": "25ch",
  "betterplace-measure-xs": "36ch",
  "betterplace-measure-sm": "49ch",
  "betterplace-measure-md": "64ch",
  "betterplace-measure-lg": "81ch",
  "betterplace-measure-xl": "100ch",
  "betterplace-measure-2xl": "121ch",
  "betterplace-viewport-width": "90rem",
  "betterplace-viewport-min": "22.5rem",
  "betterplace-viewport-max": "90rem",
  "betterplace-typography-xs": {
    fontWeight: 400,
    fontSize: "0.707rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-narrow": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold-narrow": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base": {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base-bold": {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg": {
    fontWeight: 400,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg-bold": {
    fontWeight: 700,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl": {
    fontWeight: 400,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl-bold": {
    fontWeight: 700,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-2xl-bold": {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-3xl-bold": {
    fontWeight: 700,
    fontSize: "3.363rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-4xl-bold": {
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-sizing-fix-1": "20rem",
  "betterplace-sizing-fix-2": "23.4375rem",
  "betterplace-sizing-fix-3": "48.75rem",
  "betterplace-sizing-fix-4": "60rem",
  "betterplace-sizing-fix-5": "52.875rem",
  "betterplace-sizing-fix-6": "90rem",
  "betterplace-shadow-outer-neutral-soft-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-green-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-red-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-1": {
    x: "0rem",
    y: "-0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-2": {
    x: "0rem",
    y: "-0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-3": {
    x: "0rem",
    y: "-0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-4": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-5": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-6": {
    x: "0rem",
    y: "-0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-red-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-green-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#2a5807"
  }
}, Pr = (e) => e.substring(1, 3) === "00" && e.length === 9 ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX////Z5exdL3Q7AAAAGklEQVQY02NgAAFGRkaa0gzo4gzo6hgGiTsANBAAYf1ZdsIAAAAASUVORK5CYII=')" : e;
function U(e) {
  const t = Ir[e];
  return typeof t != "object" && typeof t != "number" ? t : "#";
}
const ut = G(function({
  onClick: t,
  color: r,
  triggerSwatch: n = !1,
  className: a,
  "aria-describedby": o,
  ...i
}, c) {
  return /* @__PURE__ */ d.jsxs(
    "button",
    {
      ...i,
      className: I(X.swatch, a, {
        [X.activeSwatch]: n
      }),
      onClick: t,
      ref: c,
      type: "button",
      "aria-roledescription": n ? "colour well" : "button",
      "aria-describedby": o,
      children: [
        /* @__PURE__ */ d.jsx(
          "div",
          {
            style: {
              background: Pr(r)
            },
            className: X.swatchInner
          }
        ),
        /* @__PURE__ */ d.jsx("span", { className: "sr-only", children: r })
      ]
    }
  );
});
function Mr({
  color: e,
  presetColors: t,
  onChange: r,
  hexInputLabel: n,
  label: a,
  id: o,
  classNames: i,
  error: c,
  ...l
}) {
  const s = ge(), p = o || l.name || s, g = D(null), f = D(null);
  return /* @__PURE__ */ d.jsx(
    Ye,
    {
      name: l.name,
      id: p,
      label: a,
      error: c,
      className: I(X.picker, i == null ? void 0 : i.label),
      errorDisplay: "border",
      children: /* @__PURE__ */ d.jsx(
        Sr,
        {
          className: X.dropdown,
          trigger: /* @__PURE__ */ d.jsx(
            ut,
            {
              ...l,
              "aria-describedby": `label-${p}`,
              id: p,
              color: e,
              ref: f,
              triggerSwatch: !0,
              className: I(i == null ? void 0 : i.trigger, { [X.errorSwatch]: c })
            }
          ),
          Anchor: Ot,
          children: /* @__PURE__ */ d.jsx("div", { className: X.popover, ref: g, children: /* @__PURE__ */ d.jsxs("div", { className: X.pickerSwatches, children: [
            t.map((b) => /* @__PURE__ */ At(
              Lr,
              {
                ...l,
                key: b,
                color: b,
                onClick: () => {
                  r(b);
                },
                className: I({
                  [X.activeSwatch]: b === e,
                  [X.inactiveSwatch]: b !== e
                })
              }
            )),
            /* @__PURE__ */ d.jsx(jr, { id: p, color: e, onChange: r, alpha: !0, prefixed: !0, className: X.input })
          ] }) })
        }
      )
    }
  );
}
function Lr({ onClick: e, ...t }) {
  const { hidePopup: r } = oe(), n = v(
    (a) => {
      r(), e == null || e(a);
    },
    [e, r]
  );
  return /* @__PURE__ */ d.jsx(ut, { ...t, onClick: n });
}
function jr({ onKeyDown: e, ...t }) {
  const { hidePopup: r } = oe(), n = v(
    (a) => {
      e == null || e(a), a.key === "Enter" && r();
    },
    [e, r]
  );
  return /* @__PURE__ */ d.jsx(Er, { ...t, onKeyDown: n });
}
function xn({
  onColorChange: e,
  transparentOption: t = !1,
  value: r,
  hexInputLabel: n,
  label: a,
  ...o
}) {
  const i = [
    U("betterplace-color-light"),
    U("betterplace-color-green-500"),
    U("betterplace-color-green-700"),
    U("betterplace-color-green-900"),
    U("betterplace-color-yellow-400"),
    U("betterplace-me-color-orange-500"),
    U("betterplace-color-red-700"),
    U("betterplace-color-red-900"),
    // themeColor('orange-700'),
    // themeColor('blue-400'),
    // themeColor('blue-700'),
    // themeColor('blue-900'),
    U("betterplace-color-purple-400"),
    U("betterplace-color-purple-700"),
    U("betterplace-color-gray-500"),
    U("betterplace-color-gray-700"),
    U("betterplace-color-gray-900")
  ].filter(Boolean);
  return t && i.push("#00000000"), /* @__PURE__ */ d.jsx(
    Mr,
    {
      ...o,
      color: `#${r === "transparent" ? "00000000" : r}`,
      presetColors: i,
      onChange: e,
      hexInputLabel: n,
      label: a
    }
  );
}
function Ne(e, t, r, n, a) {
  return (e - t) * (a - n) / (r - t) + n;
}
function pt(e, t) {
  const r = e + t;
  return r > 360 ? r - 360 : r;
}
function dt() {
  return Math.random() > 0.5;
}
const mt = [0, 0, 1], ke = [
  // dual axis rotations (a bit more realistic)
  [1, 1, 0],
  [1, 0, 1],
  [0, 1, 1],
  // single axis rotations (a bit dumber)
  [1, 0, 0],
  [0, 1, 0],
  mt
];
function Fr([e, t, r]) {
  const [n, a, o] = mt;
  return n === e && a === t && o === r;
}
function vn(e) {
  return !!ke[e] && !Fr(ke[e]) && dt();
}
function te(e, t = 4) {
  const r = Math.pow(10, t);
  return Math.round(e * r) / r;
}
function ft(e) {
  return te(e, 2).toString().replace(".", "-");
}
const Ue = 200, Dr = 800, Or = 0.8, Br = 0.33, Re = 0.5, Tn = ke.reduce((e, t, r) => ({
  ...e,
  [`--confetti-rotation-3d-${r}-50`]: t.map((n) => n / 2).join(),
  [`--confetti-rotation-3d-${r}-100`]: t.join()
}), {});
function _n(e, t, r) {
  const a = {
    "--confetti-landing-point-y": typeof t == "string" ? t : `${t}px`
  };
  for (const o of e) {
    const i = `${te(Ne(Math.abs(pt(o, 90) - 180), 0, 180, -r / 2, r / 2), 2)}px`;
    a[`--confetti-degree-${ft(o)}-landing-point-x`] = i;
  }
  return a;
}
function Cn({ color: e, degree: t }, r, n, a, o) {
  const i = Math.round(Math.random() * (Dr - Ue) + Ue), c = r - Math.round(Math.random() * 1e3), s = Math.random() < Or ? te(Math.random() * Br, 2) : 0, p = s * -1, g = s, f = te(Math.abs(Ne(Math.abs(pt(t, 90) - 180), 0, 180, -1, 1)), 4), b = te(Math.random() * Re, 4), C = te(Math.random() * n * (dt() ? 1 : -1), 4), T = Re, y = te(Math.max(Ne(Math.abs(t - 180), 0, 180, n, -n), 0), 4);
  return {
    "--confetti-animation-duration": `${c}ms`,
    "--confetti-particle-width": `${o ? a : Math.round(Math.random() * 4) + a / 2}px`,
    "--confetti-particle-height": `${o ? a : Math.round(Math.random() * 2) + a}px`,
    "--confetti-particle-color": e,
    "--confetti-rotation-animation-duration": `${i}ms`,
    "--confetti-landing-point-x": `var(--confetti-degree-${ft(t)}-landing-point-x)`,
    "--confetti-x1": s,
    "--confetti-x2": p,
    "--confetti-x3": g,
    "--confetti-x4": f,
    "--confetti-y1": b,
    "--confetti-y2": C,
    "--confetti-y3": T,
    "--confetti-y4": y
  };
}
const Sn = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? L : t, c = o ? L : r;
  return /* @__PURE__ */ d.jsx(vt, { ...n, onChange: i, onClick: c, ref: a });
}), zr = G(function({
  onKeyDown: t,
  onKeyUp: r,
  onKeyDownCapture: n,
  onKeyUpCapture: a,
  onClick: o,
  ...i
}, c) {
  const { disabled: l } = i, s = l ? L : t, p = l ? L : r, g = l ? L : n, f = l ? L : a, b = l ? L : o;
  return /* @__PURE__ */ d.jsx(
    Tt,
    {
      ...i,
      onClick: b,
      onChange: s,
      onKeyUp: p,
      onKeyDownCapture: g,
      onKeyUpCapture: f,
      ref: c
    }
  );
}), $r = "_accordion_1qlmm_1", Kr = "_accordionItem_1qlmm_6", qr = "_content_1qlmm_11", Hr = "_wrapper_1qlmm_16", Ur = "_accordionSummary_1qlmm_26", Rr = "_accordionIcon_1qlmm_41", Wr = "_open_1qlmm_59", Yr = "_closing_1qlmm_72", ee = {
  accordion: $r,
  accordionItem: Kr,
  content: qr,
  wrapper: Hr,
  accordionSummary: Ur,
  accordionIcon: Rr,
  open: Wr,
  closing: Yr
}, bt = Ge({
  activeKey: void 0,
  prevKey: void 0,
  onTransitionFinished: (e) => {
  }
});
function Xr() {
  return Je(bt);
}
function An({
  label: e,
  id: t,
  className: r,
  children: n
}) {
  const { activeKey: a, onTransitionFinished: o, prevKey: i } = Xr(), [c, l] = K(a === t || i === t), s = D(null), p = D(null), g = v(
    (f) => {
      f.propertyName === "opacity" && f.target === s.current && a === t && (o(t), window.requestAnimationFrame(() => {
        if (!p.current) return;
        const b = ce(p.current);
        if (!b) return;
        const { top: C } = p.current.getBoundingClientRect(), T = b === window ? 0 : b.offsetTop;
        b.scrollBy({ behavior: "smooth", top: C - T });
      }));
    },
    [a, t, o]
  );
  return E(() => {
    if (a === i) return;
    const f = setTimeout(() => l(a === t), 1);
    return () => clearTimeout(f);
  }, [t, a, i]), // eslint-disable-next-line jsx-a11y/role-supports-aria-props
  /* @__PURE__ */ d.jsxs(
    "details",
    {
      open: !0,
      id: t,
      "aria-expanded": a === t ? "true" : "false",
      className: I(ee.accordionItem, { [ee.open]: c }, r),
      ref: p,
      children: [
        /* @__PURE__ */ d.jsxs("summary", { onClick: L, onKeyDown: L, className: ee.accordionSummary, children: [
          e,
          /* @__PURE__ */ d.jsx(We, { name: "arrowDown", className: ee.accordionIcon, size: "400" })
        ] }),
        /* @__PURE__ */ d.jsx("div", { className: ee.wrapper, onTransitionEnd: g, ref: s, children: /* @__PURE__ */ d.jsx("div", { className: ee.content, children: n }) })
      ]
    }
  );
}
function Nn({
  defaultKey: e,
  children: t,
  activeKey: r,
  onTransitionFinished: n,
  className: a
}) {
  const [o, i] = K(void 0), [c, l] = K(r ?? e), s = v(
    (p) => {
      n == null || n(p, o), i(p);
    },
    [n, o]
  );
  return E(() => {
    r && (l(r), i(c));
  }, [r]), /* @__PURE__ */ d.jsx(Xe, { className: I(ee.accordion, a), children: /* @__PURE__ */ d.jsx(bt.Provider, { value: { activeKey: c, onTransitionFinished: s, prevKey: o }, children: t }) });
}
const Gr = "_modal_xnbvu_1", Jr = "_modalInner_xnbvu_52", Qr = "_modalHeader_xnbvu_56", Zr = "_closeButton_xnbvu_71", Vr = "_modalContent_xnbvu_77", en = "_modalActions_xnbvu_81", tn = "_portalTarget_xnbvu_90", rn = "_portalTargetInert_xnbvu_97", J = {
  modal: Gr,
  modalInner: Jr,
  modalHeader: Qr,
  closeButton: Zr,
  modalContent: Vr,
  modalActions: en,
  portalTarget: tn,
  portalTargetInert: rn
};
function nn(e) {
  e && (!("close" in e) || typeof e.close != "function" || e.close());
}
const kn = G(function({
  id: t,
  isOpen: r,
  onClose: n,
  onOpen: a,
  onCloseComplete: o,
  onOpenComplete: i,
  title: c,
  customActions: l,
  children: s,
  manualFocusRef: p,
  closeText: g,
  closeOnClickOutside: f = !0,
  hideHeader: b = !1,
  hideActions: C = !1,
  classNames: T = {}
}, y) {
  const [m, _] = Ze(y), A = D(null), [j, P] = K(!0), F = v(() => p || (A.current ? A : m), [p, m]), O = ge(), u = t || O, h = v(
    (w) => {
      var V;
      if (w.key === "Escape") return n(w);
      if (w.key !== "Tab") return;
      const x = (V = m.current) == null ? void 0 : V.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      ), N = x == null ? void 0 : x[0], Y = x == null ? void 0 : x[x.length - 1];
      if (w.shiftKey && document.activeElement === N) {
        w.preventDefault(), Y == null || Y.focus();
        return;
      }
      !w.shiftKey && document.activeElement === Y && (w.preventDefault(), N == null || N.focus());
    },
    [m, n]
  ), M = v(() => {
    o == null || o(), P(!0);
  }, [o]), S = v(() => {
    var w;
    i == null || i(), P(!0), (w = m.current) == null || w.removeAttribute("inert");
  }, [m, i]);
  E(() => {
    var x;
    P(!1);
    const w = m.current;
    if (w) {
      if (r) {
        w.showModal();
        const N = F();
        (x = N == null ? void 0 : N.current) == null || x.focus(), w.removeEventListener("transitionend", M), w.addEventListener("keydown", h), w.addEventListener("transitionend", S), a == null || a();
      } else
        w.removeEventListener("keydown", h), w.removeEventListener("transitionend", S), w.addEventListener("transitionend", M), nn(w), w.setAttribute("inert", "");
      return () => {
        w == null || w.removeEventListener("keydown", h), w.removeEventListener("transitionend", M), w.removeEventListener("transitionend", S);
      };
    }
  }, [F, h, r, m, M, a, S]);
  const $ = v(
    (w) => {
      const { current: x } = m;
      w.target === x && n(w);
    },
    [m, n]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ d.jsxs(
      "dialog",
      {
        id: u,
        ref: _,
        className: I(J.modal, T.modal),
        onCancel: n,
        onClick: f ? $ : void 0,
        "aria-labelledby": `modal-title-${u}`,
        "aria-modal": "true",
        title: c,
        tabIndex: -1,
        children: [
          /* @__PURE__ */ d.jsxs(Xe, { space: "400", className: I(J.modalInner, T.modalInner), children: [
            !b && /* @__PURE__ */ d.jsxs("div", { className: I(J.modalHeader, T.titleContainer), children: [
              /* @__PURE__ */ d.jsx(_t, { level: "h200", as: "h2", id: `modal-title-${u}`, children: c }),
              /* @__PURE__ */ d.jsx(
                zr,
                {
                  kind: "plain",
                  className: J.closeButton,
                  onClick: n,
                  ref: A,
                  type: "button",
                  iconName: "x",
                  iconProps: { title: g, color: "fg-content-primary" }
                }
              )
            ] }),
            /* @__PURE__ */ d.jsx("div", { className: I(J.modalContent, T.contentContainer), children: (r || !j) && s }),
            !C && /* @__PURE__ */ d.jsx("div", { className: I(J.modalActions, T.actionsContainer), children: l || /* @__PURE__ */ d.jsx(Lt, { onClick: n, type: "button", children: g }) })
          ] }),
          r && /* @__PURE__ */ d.jsxs(d.Fragment, { children: [
            /* @__PURE__ */ d.jsx("div", { id: "bp-portal-target", className: J.portalTarget, role: "alert" }),
            /* @__PURE__ */ d.jsx("div", { id: "bp-portal-target-inert", className: J.portalTargetInert, role: "alert" })
          ] })
        ]
      }
    )
  );
}), En = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? L : t, c = o ? L : r;
  return /* @__PURE__ */ d.jsx(Ct, { ...n, onChange: i, onClick: c, ref: a });
}), on = "_input_1bj67_1", an = "_growWrap_1bj67_2", me = {
  input: on,
  growWrap: an,
  "input--error": "_input--error_1bj67_29"
}, In = G(function({
  label: t,
  name: r,
  description: n,
  error: a,
  warning: o,
  id: i,
  required: c,
  className: l,
  onChange: s,
  errorDisplay: p,
  rows: g = 1,
  fullWidth: f,
  ...b
}, C) {
  const T = D(null), y = [];
  return a && y.push(`${r}-error`), o && y.push(`${r}-warning`), n && y.push(`${r}-description`), /* @__PURE__ */ d.jsx(
    Ye,
    {
      label: t,
      name: r,
      description: n,
      error: a,
      warning: o,
      id: i || r,
      required: c,
      errorDisplay: p,
      fullWidth: f,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ d.jsx(
        "div",
        {
          className: I(me.growWrap, l, {
            [me.wrapError]: !!a,
            fullWidth: f && f !== "mobile-only",
            fullWidthOnMobile: f && f === "mobile-only"
          }),
          ref: T,
          children: /* @__PURE__ */ d.jsx(
            "textarea",
            {
              ...b,
              ref: C,
              id: i || r,
              name: r,
              "aria-describedby": y.length ? y.join(" ") : void 0,
              "aria-invalid": !!a,
              "aria-required": c,
              className: I(me.input, l, {
                [me["input--error"]]: !!a,
                fullWidth: f && f !== "mobile-only",
                fullWidthOnMobile: f && f === "mobile-only"
              }),
              rows: g,
              onChange: (m) => {
                var _;
                (_ = T.current) == null || _.setAttribute("data-replicated-value", m.target.value), s && s(m);
              }
            }
          )
        }
      )
    }
  );
}), cn = "_button_rqisg_1", ln = "_hiddenInput_rqisg_58", ve = {
  button: cn,
  hiddenInput: ln,
  "size-large": "_size-large_rqisg_64",
  "size-default": "_size-default_rqisg_69",
  "size-small": "_size-small_rqisg_74"
}, Pn = G(function({
  id: t,
  children: r,
  value: n,
  name: a,
  onChange: o,
  onClick: i,
  className: c,
  type: l = "radio",
  size: s = "default",
  disabled_: p,
  disabled: g,
  fullWidth: f,
  ...b
}, C) {
  const T = I(ve.button, ve[`size-${s}`], c), y = ge(), _ = `${t || a || y}-${String(n)}`, A = g || p;
  return /* @__PURE__ */ d.jsxs(
    "label",
    {
      htmlFor: _,
      className: I(
        {
          fullWidth: f && f !== "mobile-only",
          fullWidthOnMobile: f && f === "mobile-only"
        },
        T
      ),
      children: [
        /* @__PURE__ */ d.jsx(
          "input",
          {
            ...b,
            ref: C,
            id: _,
            "aria-disabled": A,
            type: l,
            value: n,
            name: a,
            onClick: A ? L : i,
            onChange: A ? L : o,
            className: ve.hiddenInput,
            disabled: p
          }
        ),
        r
      ]
    }
  );
}), Mn = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? L : t, c = o ? L : r;
  return /* @__PURE__ */ d.jsx(St, { ...n, onChange: i, onClick: c, ref: a });
});
function sn(e, t) {
  return {
    position: "absolute",
    inset: 0,
    transform: `translateY(${e * t}px)`,
    height: `${t}px`
  };
}
const Te = {
  Up: "Up",
  Down: "Down",
  None: null
};
function un({
  className: e,
  style: t,
  children: r,
  overscanRows: n,
  maxRows: a,
  rowHeight: o,
  rows: i,
  onScroll: c,
  initialRow: l
}) {
  const s = D(null), p = n || i, g = D(/* @__PURE__ */ new Map()), f = D(null), b = D((l ?? 0) * o), [C, T] = K(0), y = D(Te.None), m = a ?? C + (i + p) * 2 + 1, _ = i * o, A = o * m;
  function j(S) {
    return g.current.has(S) || g.current.set(S, sn(S, o)), g.current.get(S);
  }
  const P = v(
    (S) => {
      const $ = Math.max(0, S - p), w = Math.min(
        m - 1,
        // don't render past the end of the list
        S + i + p
      );
      return [$, w];
    },
    [m, p, i]
  ), F = [], [O, u] = P(C);
  for (let S = O; S <= u; S++)
    F.push(/* @__PURE__ */ d.jsx(r, { index: S, style: j(S) }, S));
  const h = v(
    (S) => {
      f.current && clearTimeout(f.current), setTimeout(() => {
        c == null || c(S), f.current = null;
      }, 50);
    },
    [c]
  ), M = v(
    (S) => {
      const $ = b.current;
      b.current = S.currentTarget.scrollTop;
      const w = Math.floor(b.current / o);
      y.current = $ < b.current ? Te.Down : Te.Up, h(P(w)), T(w);
    },
    [P, h, o]
  );
  return E(() => {
    var S;
    l && ((S = s.current) == null || S.scrollTo({ left: 0, top: l * o, behavior: "instant" }), c == null || c(P(l)));
  }, [P, l, c, o]), E(() => () => {
    f != null && f.current && clearTimeout(f.current);
  }, []), /* @__PURE__ */ d.jsx(
    "div",
    {
      className: e,
      style: {
        ...t,
        overflowY: "auto",
        position: "relative",
        scrollBehavior: "smooth",
        height: _,
        willChange: "contents"
      },
      ref: s,
      onScroll: M,
      children: /* @__PURE__ */ d.jsx("div", { style: { position: "absolute", top: 0, left: 0, right: 0, height: A }, children: F })
    }
  );
}
const Ln = Nt(un);
export {
  Q as A,
  pr as B,
  Pt as C,
  Sr as D,
  nt as E,
  dr as F,
  Pe as G,
  Ce as H,
  $e as I,
  mr as J,
  ce as K,
  Nn as L,
  ot as M,
  ie as N,
  En as O,
  xr as P,
  Pn as Q,
  Mn as R,
  Ze as S,
  In as T,
  z as U,
  Ln as V,
  Cn as a,
  ke as b,
  Ot as c,
  q as d,
  Lt as e,
  fn as f,
  _n as g,
  xn as h,
  gn as i,
  yn as j,
  wn as k,
  bn as l,
  hn as m,
  Sn as n,
  zr as o,
  An as p,
  kn as q,
  Tn as r,
  vn as s,
  sr as t,
  Xr as u,
  ur as v,
  Ve as w,
  ye as x,
  Ie as y,
  rt as z
};
